import React, {useEffect} from "react";
import axios from "axios";
import language from "./language";
import {Link} from "react-router-dom";
import strings from "./strings";
import {BlogEntry} from "./interface/BlogEntry";
import ReactGA from "react-ga4";

function Home() {

    useEffect(() => {
        // Send pageview with a custom path
        ReactGA.send({hitType: "pageview", page: "/", title: "Home Page"});
    }, [])

    const [title, setTitle] = React.useState('');
    const [text, setText] = React.useState('');
    const [list, setList] = React.useState<BlogEntry[]>([]);

    const lang = language();
    const blogLang = 'any';
    const numberOfBlogItems = 5;
    const baseUrl = process.env.REACT_APP_API_URL + "/site/" + process.env.REACT_APP_SITE_NAME + "/" + lang + "/home";
    const blogUrl = process.env.REACT_APP_API_URL + "/blogs/" + process.env.REACT_APP_SITE_NAME + "/" + blogLang + "/" + numberOfBlogItems;

    useEffect(() => {
        post(baseUrl)
    }, [baseUrl]);

    useEffect(() => {
        get_blog_list(blogUrl);
    }, [blogUrl]);

    function post(url: string) {
        const headers = {
            "Content-Type": "application/json",
        };

        // @ts-ignore
        axios.get(url, headers)
            .then(response => {

                    setTitle(response.data.title);
                    setText(response.data.text);
                }
            )
            .catch(error => {
                console.log(error)
            });
    }

    function get_blog_list(url: string) {
        const headers = {
            "Content-Type": "application/json",
        };

        // @ts-ignore
        axios.get(url, headers)
            .then(response => {
                    setList(Array.from(response.data));
                    window.scrollTo(0, 0)
                }
            )
            .catch(error => {
                console.log(error)
            });
    }

    let blog_list
    if (list !== undefined && list.length > 0) {
        blog_list = list.map((item) =>
            <div key={item.id}>
                <div className='blog_list_item mt-3 ml-3 mr-3'><Link to={'/blog/' + item.id}>{item.title}</Link>
                    <div
                        className='date_string'> {(item.date_string !== undefined && item.date_string.length > 10) ? item.date_string.substring(0, 10) : ''}</div>
                </div>
            </div>
        );
    }

    return (

        <div>
            <div className="float-container">
                <div className="d-none d-xl-block">
                    <div className="float-child-left">
                        <div className='home-content pt-1 pl-5 pr-5 pb-5 '>
                            <h2>  {title}  </h2>
                            <div className='pr-5'  dangerouslySetInnerHTML={{__html: text}}/>
                        </div>
                    </div>
                </div>
                <div className='d-block d-xl-none'>
                    <div className="float-child-left">
                        <div className='home-content '>
                            <h2>  {title}  </h2>
                            <div dangerouslySetInnerHTML={{__html: text}}/>
                        </div>
                    </div>
                </div>
                <div className="float-child-right">
                    <div id="sidebar-wrapper">
                        <p className="sidebar-blogs">

                            <div id='linkContainer' className='ml-0'>

                                <div className='mb-5'>
                                    <img src="https://content.christine.nl/pics/tek-Karman-300.jpg" width="300px"
                                         alt="tek Karman"/>
                                    <a href="https://www.bol.com/nl/nl/p/agile-zoals-het-bedoeld-is/9200000117890588/?suggestionType=searchhistory&bltgh=ioNdn8a08w-iRyV6GFdNvA.1.2.ProductTitle">
                                        <img className="mt-5 ml-5"
                                             src="https://content.christine.nl/website-images/boek-cover.jpg"
                                             alt="boek"/></a>
                                </div>
                                <hr></hr>
                                <p className='mt-3 ml-5 mr-5'>{strings.blog}</p>
                                <div>{blog_list}</div>
                            </div>
                        </p>
                    </div>
                </div>

            </div>
        </div>
    )

}

export default Home
