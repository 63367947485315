import React, {useEffect, useState} from "react";
import axios from "axios";
import {Link, Navigate, useLocation, useNavigate} from "react-router-dom";
import strings from "./strings";
import AuthenticationService from './service/AuthenticationService';
import {BlogEntry} from "./interface/BlogEntry";
import ReactGA from "react-ga4";
import {DateString} from "./DateString";

function Blog() {

    useEffect(() => {
        // Send pageview with a custom path
        ReactGA.send({hitType: "pageview", page: "/blog", title: "Blog"});
    }, [])

    const location = useLocation()
    const [blog_language] = useState('nl');
    const navigate = useNavigate()

    useEffect(() => {
        const path = location.pathname.split('/')
        let _id = 0;
        if (path.length > 2 && path[2] !== undefined) {
            _id = parseInt(path[2])
            setId(_id)
        }
        post(`${process.env.REACT_APP_API_URL}/blog/${process.env.REACT_APP_SITE_NAME}/${blog_language}/${_id !== 0 ? _id : ''}`)
    }, [location.pathname, blog_language]);

    useEffect(() => {
        get_blog_list(10);
    }, []);

    const [title, setTitle] = React.useState('');
    const [text, setText] = React.useState('');
    const [id, setId] = React.useState(0);
    const [date, setDate] = React.useState('');
    const [list, setList] = React.useState<BlogEntry[]>([]);
    const [go_edit, setGoEdit] = useState(false);
    const [go_create, setGoCreate] = useState(false);

    console.log('pathname: ' + location.pathname)

    function post(url: string) {
        const headers = {
            "Content-Type": "application/json",
        };

        // @ts-ignore
        axios.get(url, headers)
            .then(response => {
                    setTitle(response.data.title);
                    setText(response.data.text);
                    setId(response.data.id);
                    setDate(DateString(response.data));
                }
            )
            .catch(error => {
                console.log(error)
            });
    }

    function logout() {

        AuthenticationService.logout()

        let postData = {
            request_code: 0
        };

        let axiosConfig = AuthenticationService.getAxiosConfig();

        const url = process.env.REACT_APP_API_URL + '/user/signout';

        axios.post(url,
            postData,
            axiosConfig
        )
            .then(response => {
                }
            )
            .catch(error => {
                console.log(error)
            });
    }

    function get_blog_list(count: string | number) {
        const headers = {
            "Content-Type": "application/json",
        };

        const url = process.env.REACT_APP_API_URL + "/blogs/" + process.env.REACT_APP_SITE_NAME + "/any/" + count

        // @ts-ignore
        axios.get(url, headers)
            .then(response => {
                    setList(Array.from(response.data));
                    window.scrollTo(0, 0)
                }
            )
            .catch(error => {
                console.log(error)
            });
    }

    function previous() {
        //  this.post(process.env.REACT_APP_API_URL + "/previous_blog/" + process.env.REACT_APP_SITE_NAME + "/" + this.state.blog_language + "/" + this.state.id
        post(`${process.env.REACT_APP_API_URL}/previous_blog/${process.env.REACT_APP_SITE_NAME}/${blog_language}/${id !== undefined ? id : ''}`)
    }

    function next() {
        post(`${process.env.REACT_APP_API_URL}/next_blog/${process.env.REACT_APP_SITE_NAME}/${blog_language}/${id !== undefined ? id : ''}`)
    }

    function showAll() {
        navigate('/all_blogs')
    }

    function blog_link(event: number) {
        setId(event);
        post(process.env.REACT_APP_API_URL + "/blog/" + process.env.REACT_APP_SITE_NAME + "/" + blog_language + "/" + event)
    }

    const redirect = '/edit_blog/' + id
    if (go_edit === true) {
        return <Navigate to={redirect}/>
    }
    if (go_create === true) {
        return <Navigate to='/edit_blog/'/>
    }

    let blog_list

    if (list !== undefined && list.length > 0) {
        blog_list = list.map((item) =>
            <div key={item.id}>
                <div className='blog_list_item mt-3'><Link to={'/blog/'}
                                                           onClick={() => blog_link(item.id)}>{item.title}</Link>
                    <div className='date_string'> {item.date_string}</div>
                </div>
            </div>
        );
    }

    function goCreate() {
        setGoCreate(true)
    }

    function goEdit() {
        setGoEdit(true)
    }

    return (
        <div>
            <div className="float-container">
                <div className='d-block d-xl-none'>
                    <div className='float-child-left-100'>
                        <div className='home-content'>
                            <div>
                                <button
                                    className="btn btn-link mr-3"
                                    onClick={previous}>
                                    {strings.previous}
                                </button>
                                <button
                                    className="btn btn-link "
                                    onClick={next}>
                                    {strings.next}
                                </button>
                                <button
                                    className="btn btn-link mr-3"
                                    onClick={showAll}>
                                    {strings.all_blogs}
                                </button>
                            </div>
                            <h2 className='mt-3'>{title}</h2>
                            <p className='blog-date-font'>{date} {id}</p>
                            <div className='mt-5' dangerouslySetInnerHTML={{__html: text}}/>
                        </div>
                    </div>

                </div>
                <div className="d-none d-xl-block">
                    <div className='float-child-left'>
                        <div className='home-content pt-1 pl-5 pr-5 pb-5 '>
                            <div>
                                <button
                                    className="btn btn-link mr-3"
                                    onClick={previous}>
                                    {strings.previous}
                                </button>
                                <button
                                    className="btn btn-link "
                                    onClick={next}>
                                    {strings.next}
                                </button>
                            </div>
                            <h2 className='mt-3'>{title}</h2>
                            <p className='blog-date-font'>{date} {id}</p>
                            <div className='pr-5' dangerouslySetInnerHTML={{__html: text}}/>
                        </div>
                    </div>

                    <div className="float-child-right">
                        <div id="sidebar-wrapper">
                            <ul className="sidebar-nav-blog">
                                <li className="sidebar-brand"></li>
                                <div id='linkContainer' className='ml-3'>
                                    <div>
                                        {
                                            AuthenticationService.isAdmin() === 'true' ?
                                                <span>
                                                <button
                                                    className="btn btn-outline-success mr-3"
                                                    onClick={goEdit}>
                                                    Edit
                                                </button>
                                                  <button
                                                      className="btn btn-outline-success mr-3"
                                                      onClick={goCreate}>
                                                    New
                                                  </button>
                                                    <button
                                                        className="btn btn-outline-warning"
                                                        onClick={logout}>
                                                            Logout
                                                        </button>
                                                </span>
                                                : null
                                        }
                                    </div>
                                </div>
                                <div>{blog_list}</div>
                                <div className='mt-5 ml-3'>
                                    <Link to='/all_blogs'>{strings.meer}</Link>
                                </div>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Blog
