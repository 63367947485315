import React, {useEffect} from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
} from "react-router-dom";
import About from './About'
import Home from './Home'
import Blog from './Blog'
import Blogs from './Blogs'
import Login from './Login'
import EditBlog from './EditBlog'
import logo180 from './images/logo180.png'
import './App.css';
import './css/bootstrap.css'
import strings from './strings.js'
import Blogstech from "./Blogstech";
import Blogsart from "./Blogsart";
import Blogstaal from "./Blogstaal";
import SchwartzeAnsingh from "./SchwartzeAnsingh";
import ReactGA from 'react-ga4'

function App() {

    useEffect(() => {
        ReactGA.initialize("G-K1W8N9CEZY");
        // Send pageview with a custom path
        ReactGA.send({hitType: "pageview", page: "/", title: "Landing Page"});
    }, [])

    const userLang = navigator.language.substring(0, 2);
    strings.setLanguage(userLang);


    return (
        <Router>
            <div className='container'>
                <div className="d-block d-sm-none">
                    <div className='header-bar header-bar-color pb-2'>
                        <h1 className='header-title'>{strings.titel}</h1>
                        <table>
                            <tbody>
                            <tr>
                                <td>
                                    <p className="navbar-nav ml-3"><Link to="/">{strings.home}</Link></p>
                                </td>

                                <td>
                                    <p className="navbar-nav ml-3"><Link to="/blog">{strings.blog}</Link>
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p className="navbar-nav ml-3"><Link
                                        to="/schwartze/">{strings.schwartzeansingh}</Link></p>
                                </td>

                                <td>
                                    <p className="navbar-nav ml-3 "><Link
                                        to="/about">{strings.about}</Link></p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="d-none d-sm-block">
                    <div className='jumbotron '>
                        <div className='header-bar header-bar-color'>
                            <table width="100%">
                                <tbody>
                                <tr>
                                    <td>
                                        <h1 className='header-title'>{strings.titel}</h1>
                                        <nav className='navbar navbar-expand-lg navbar-light header-text'>
                                            <p className="navbar-nav"><Link to="/">{strings.home}</Link></p>
                                            <p className="navbar-nav"><Link to="/blog">{strings.blog}</Link>
                                            </p>
                                            <p className="navbar-nav"><Link
                                                to="/blogstech/tech">{strings.tech}</Link>
                                            </p>
                                            <p className="navbar-nav"><Link
                                                to="/blogstaal/taalblog">{strings.taal}</Link></p>
                                            <p className="navbar-nav"><Link
                                                to="/blogsart/art">{strings.art}</Link></p>
                                            <p className="navbar-nav"><Link
                                                to="/schwartze/">{strings.schwartzeansingh}</Link></p>
                                            <p className="navbar-nav ml-5"><Link
                                                to="/about">{strings.about}</Link></p>
                                        </nav>
                                    </td>
                                    <td>
                                        <img src={logo180} className="logo" alt="logo"/>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className=' content'>
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/about" element={<About/>}/>
                        <Route path="/blog/:id" element={<Blog/>}/>
                        <Route path="/blog" element={<Blog/>}/>
                        <Route path="/blogstech/:cat" element={<Blogstech/>}/>
                        <Route path="/blogsart/:cat" element={<Blogsart/>}/>
                        <Route path="/blogstaal/:cat" element={<Blogstaal/>}/>
                        <Route path="/all_blogs" element={<Blogs/>}/>
                        <Route path="/edit_blog/:id" element={<EditBlog/>}/>
                        <Route path="/edit_blog" element={<EditBlog/>}/>
                        <Route path="/schwartze" element={<SchwartzeAnsingh/>}/>
                        <Route path="/login" element={<Login/>}/>
                    </Routes>
                </div>
            </div>
        </Router>
    )
}


export default App;
