import React, {Component} from "react";
import axios from "axios";
import {Link, Navigate} from "react-router-dom";
import strings from "./strings";
import AuthenticationService from "./service/AuthenticationService";

class Blogs extends Component<any, any> {

    state: {
        language: string;
        text: string;
        title: string;
        list: any[],
        show_blog: boolean,
        id: number,
        date: string,
        go_edit: boolean,
        go_create: boolean,
        show_all: boolean,
        blog_language: string
    }

    constructor(props: any) {
        super(props)

        this.state = {
            language: "",
            text: "",
            title: "",
            list: [],
            show_blog: false,
            id: 0,
            date: '',
            go_edit: false,
            go_create: false,
            show_all: false,
            blog_language: ''
        }

        strings.setLanguage(navigator.language.substring(0, 2))

        this.get = this.get.bind(this)

        const params = window.location.href.split('/')
        const category: string = params[4];
        if (category !== undefined) {
            this.postCat(`${process.env.REACT_APP_API_URL}/categories/`, category)
        } else {
            this.get(`${process.env.REACT_APP_API_URL}/all_blogs/${process.env.REACT_APP_SITE_NAME}/nl`);
        }


    }

    postCat(url: string, category: string) {

        const postData = {
            site: 'christine',
            language: 'any',
            categories: category === 'tech' ? ['Programming', 'Agile'] : (category === 'art' ? ['Art History'] : ['Vlekjes'])
        }
        let axiosConfig = AuthenticationService.getAxiosConfig();

        axios.post(url, postData, axiosConfig)
            .then(response => {
                    this.setState({
                        list: response.data,
                    })
                }
            )
            .catch(error => {
                console.log(error)
            });
    }

    get(url: string) {
        const headers = {
            "Content-Type": "application/json",
        };

        // @ts-ignore
        axios.get(url, headers)
            .then(response => {
                    this.setState({
                        list: response.data,
                    })
                }
            )
            .catch(error => {
                console.log(error)
            });
    }

    render() {

        if (this.state.show_blog) {
            <Navigate to={'blog/' + 99}/>
        }

        const list = this.state.list
        let blog_list: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | JSX.Element[] | null | undefined = []

        if (list !== undefined) {
            blog_list = list.map((item) =>
                <div key={item.id}>
                    <table><tbody><tr>
                        <div className='blog_list_item mt-3'>
                            <td width='90'>{item.date_string.substring(0, 10)}</td>
                            <td><Link className='ml-3' to={'/blog/' + item.id}>{item.title}</Link></td>
                    </div>
                    </tr></tbody></table>
                </div>
            );
        }

        return (

            <div className="float-container">
                <div className="d-block d-sm-none">
                    <div className='float-child-left-100'>
                        <div className='home-content'>
                            <div>

                                <div className='mt-5'>
                                    {blog_list}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-none d-xl-block">
                    <div className='float-child-left'>
                        <div className='home-content'>
                            <div>

                                <div className='mt-5'>
                                    {blog_list}
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="float-child-right">
                        <p className="sidebar-blogs">
                            <img src="https://content.christine.nl/pics/3poezen.jpg" width="300px" alt="3 poezen"/>
                            <img src="https://content.christine.nl/pics/vlekjes.jpg" width="300px" alt="vlekjes"/>
                            <img src="https://content.christine.nl/pics/pengo.jpg" width="300px" alt="pengo"/>
                            <img src="https://content.christine.nl/pics/mitnick.jpg" width="300px" alt="mitnick"/>
                            <img src="https://content.christine.nl/pics/poezen/PXL_20231125_131443133.jpg"
                                 width="300px"
                                 alt="poezen"/>
                            <img src="https://content.christine.nl/pics/moortjepoes600.jpg" width="300px"
                                 alt="moortjepoes"/>
                        </p>
                    </div>
                </div>
            </div>
        )

    }
}

export default Blogs
