import React, {Component} from "react";
import language from "./language";
import axios from "axios";
import strings from "./strings";

class SchwartzeAnsingh extends Component {

    state: { language: string; text: string; title: string; list: any[] };

    constructor(props: any) {
        super(props)

        const lang = language()
        const baseUrl = process.env.REACT_APP_API_URL + "/site/" + process.env.REACT_APP_SITE_NAME + "/" + lang + "/schwartze";

        this.state = {
            title: '',
            text: '',
            list: [],
            language: lang
        }
        this.post = this.post.bind(this)

        this.post(baseUrl)
    }

    post(url: string) {
        const headers = {
            "Content-Type": "application/json",
        };

        // @ts-ignore
        axios.get(url, headers)
            .then(response => {
                    this.setState({
                        title: response.data.title,
                        text: response.data.text,
                        id: response.data.id,
                        date: response.data.date_string
                    })
                }
            )
            .catch(error => {
                console.log(error)
            });
    }

    render() {

        const text = this.state.text;

        return (
            <div className="float-container">
                <div className='d-block d-xl-none'>
                    <div className="float-child-left-100">
                        <div className='home-content'>
                            <h2>  {this.state.title}  </h2>

                        </div>
                        <div className='mb-5'>
                            <img
                                src="https://www.schwartze-ansingh.com/static/media/logo.be06b4fa3f539d040801.png"
                                width="100%" alt="logo"/>
                        </div>
                        <div className='home-content'>
                            <div dangerouslySetInnerHTML={{__html: text}}/>
                        </div>
                        <div className="mt-5 ml-5">
                            <a href="https://www.schwartze-ansingh.com">{strings.letter_collection}</a>
                        </div>
                    </div>
                </div>
                <div className="d-none d-xl-block">
                    <div className="float-child-left">
                        <div className='home-content pt-1 pl-5 pr-5 pb-5 '>
                            <h2>  {this.state.title}  </h2>
                            <div dangerouslySetInnerHTML={{__html: text}}/>
                        </div>
                        <div className="mt-5 ml-5">
                            <a href="https://www.schwartze-ansingh.com">{strings.letter_collection}</a>
                        </div>
                    </div>
                </div>
                <div className="d-none d-xl-block">
                    <div className="float-child-right">
                        <div id="sidebar-wrapper">
                            <p className="sidebar-blogs">
                                <div id='linkContainer' className='ml-0'>
                                    <div className='mb-5'>
                                        <img
                                            src="https://www.schwartze-ansingh.com/static/media/logo.be06b4fa3f539d040801.png"
                                            width="300px" alt="logo"/>
                                        <img className="mt-5" width="300"
                                             src="https://content.christine.nl/schwartze/doosjes-800.jpg"
                                             alt="doosjes"/>
                                    </div>
                                </div>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SchwartzeAnsingh
